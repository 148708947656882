import React from "react"
import { Link } from "gatsby";
import { appConfig, SignUpState } from "../utils/environment";
import Head from "../components/head";
import Layout from "../components/layout";
import GenericError from "../components/generic-error";

import Unsub from "../../static/images/unsubscribe.svg"

export default class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unsubComplete: false,
            processingRequest: false
        }
    }
    sendConfirm() {
        this.setState({ processingRequest: true });
        const search = this.props.location.search;
        const email = new URLSearchParams(search).get('email');
        const key = new URLSearchParams(search).get('key');

        if (!email || !key) {
            this.setState({ processingRequest: false, unsubComplete: SignUpState.ERROR })
            return;
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                unsubscribe: true,
                email,
                key
            })
        };

        fetch(appConfig.signUpUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    this.setState({ processingRequest: false, unsubComplete: SignUpState.COMPLETE });
                } else {
                    throw new Error(data);
                }
                this.setState({ processingRequest: false, unsubComplete: SignUpState.COMPLETE });
            }).catch(error => {
                this.setState({ processingRequest: false, unsubComplete: SignUpState.ERROR })
            });
    }
    componentDidMount() {
        this.sendConfirm();
    }

    render() {
        return (
            <>
                <Head title={"Unsubscribe"} />
                <Layout>
                    <div className="flex-col flex md:flex-row">
                        <div className="w-full mb-4 mx-8 space-y-3 p-5 md:mb-0 ">
                            {!this.state.processingRequest && this.state.unsubComplete === SignUpState.COMPLETE && (
                                <div className="justify-center items-center w-100 md:flex md:flex-col">

                                    <img
                                        src={Unsub}
                                        alt="A person standing next to a giant opened envelope"
                                        className="w-100 md:w-1/4 mb-3"
                                    />
                                    <h2 className="normalize italic font-bold">You are unsubscribed</h2>
                                    <p className="w-100 md:w-1/3 text-center">You have unsubscribed from the Digital Sabbath, we’re sad to see you go!</p>
                                    <div className="my-4 py-3">
                                        <Link
                                            to={"/"}
                                            className="w-full md:w-40 btn-primary"
                                        >
                                            I've changed my mind
                                     </Link>
                                    </div>
                                </div>
                            )}
                            {!this.state.processingRequest && this.state.unsubComplete === SignUpState.ERROR && (
                                <GenericError />)}
                            {this.state.processingRequest && (
                                <div>
                                    <h2 className="text-center">Processing your request...</h2>
                                </div>
                            )}
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}
